import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DrawerHeader } from "../components/DrawerHeaderSpace";

import {
  Container,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

const UserEditScreen = ({ match }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [tenant, setTenant] = useState("");
  const [role, setRole] = useState("");
  //   const [user, setUser] = useState({});
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserById = async () => {
      const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
      let localToken;
      if (loginStatus) {
        localToken = loginStatus;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };
      const response = await axios.get(`/api/users/${id}`, config);
      // console.log(response.data);
      //   setUser(response.data);
      setName(response.data.name);
      setEmail(response.data.email);
      setIsAdmin(response.data.isAdmin);
      setTenant(response.data.tenant);
      setRole(response.data.role);
      setIsLoading(false);
      return response?.data;
    };
    getUserById();
  }, [id]);

  const SubmitHandle = async (e) => {
    // e.preventdefault();
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localToken;
    if (loginStatus) {
      localToken = loginStatus;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localToken}`,
      },
    };
    const response = await axios.put(
      `/api/users/${id}`,
      {
        name: name,
        email: email,
        isAdmin: isAdmin,
        tenant: tenant,
        role: role,
      },
      config
    );
    // console.log(response.data);
    navigate("/admin/userlist", { replace: true });
    return response?.data;
  };

  return (
    <Container>
      <DrawerHeader />
      <Button variant="outlined" style={{ margin: 5 }}>
        <Link to="/admin/userlist">Go Back</Link>
      </Button>

      <h4>
        <center>Update User</center>
      </h4>
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: 20,
          }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              id="name"
              variant="outlined"
              type="name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              variant="outlined"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>

          <Select
            labelId="Role"
            id="role"
            label="Role"
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            sx={{ m: 1, minWidth: 220 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="user">user</MenuItem>
            <MenuItem value="admin">admin</MenuItem>
          </Select>

          <Grid item xs={12} md={6}>
            <Button variant="contained" onClick={SubmitHandle}>
              Update
            </Button>
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default UserEditScreen;
