import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Message from "../components/Message";
import { Container, Grid, TextField, Button } from "@mui/material";
import axios from "axios";
import { adduserInfo } from "../redux/Items/cartSlice";
import { useDispatch } from "react-redux";

const LoginScreen = ({ location }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState("");

  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (loginStatus) {
      navigate("/home", { replace: true });
    }
  }, [isLoading, navigate]);

  const loginUser = async (email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/login",
        { email, password },
        config
      );
      localStorage.setItem("userInfo", JSON.stringify(data.token));
      navigate(0);
      dispatch(
        adduserInfo({
          id: data._id,
          name: data.name,
          email: data.email,
          isAdmin: data.isAdmin,
          token: data.token,
        })
      );

      setLoading(true);
    } catch (error) {
      setError(error.response.data.message);
      console.log("error " + error.response.data.message);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setError("Email or password cannot be empty");
    } else {
      loginUser(email, password);
      // if (isLoading) {
      //   navigate("/", { replace: isLoading });
      // }
    }
  };

  return (
    <Container>
      <div style={{ margin: 40 }}>
        <h4>
          <center>Sign In</center>
        </h4>
      </div>
      {isError !== "" ? <Message variant="error">{isError}</Message> : <></>}

      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          margin: 20,
        }}
      >
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="name"
            variant="outlined"
            label="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              margin: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="password"
            type="password"
            variant="outlined"
            label="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              margin: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={submitHandler}>
            Login
          </Button>
        </Grid>
      </div>
    </Container>
  );
};

export default LoginScreen;
