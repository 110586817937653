import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";

const BASE_URL = "/api/products";

const initialState = {
  products: [],
  loading: false,
  error: "",
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localTenant;
    if (loginStatus) {
      localTenant = jwt_decode(loginStatus).payload.tenant;
    }

    // else {
    //   localTenant = "testtenant";
    // }
    const response = await axios.get(BASE_URL + `/tenant/${localTenant}`);
    // console.log(response.data);
    return response?.data;
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // ==> normal reducer functions go here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.loading = false;
        state.products = [];
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = true;
        state.products = state.products.concat(action.payload);
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

// export const selectAllPosts = (state) => state.products.products;
// export const getPostsError = (state) => state.products.error;
// export const getPostsStatus = (state) => state.products.status;

export default productsSlice.reducer;
