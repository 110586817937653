import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const RenderCard = ({ name, value, imagePath }) => {
  return (
    <Card sx={{ display: "flex" }} style={{ margin: 10 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div">{name}</Typography>
          <Typography
            variant="subtitle1"
            color="#0096FF"
            component="div"
            style={{ marginTop: 20 }}
          >
            <h3> {value}</h3>
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 100, height: 100 }}
        image={require("../static/" + imagePath)}
        alt="data"
      />
    </Card>
  );
};

export default RenderCard;
