import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalPrice: 0,
  itemCount: 0,
  totalTaxes: 0,
  totalTaxAmount: 0,
  userInfo: {
    _id: "",
    name: "",
    email: "",
    isAdmin: "",
    token: "",
  },
  name: "",
  mobile: "9999999999",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const indexOfElement = state.items.findIndex(
        (key) => key.id === action.payload.id
      );
      const units = 1;
      if (indexOfElement >= 0) {
        const newCart = state.items.slice();
        const eProduct = newCart[indexOfElement];

        if (eProduct.productActualQty <= eProduct.units) {
          window.alert("Product : " + eProduct.name + " is out of stock !!!");
          return;
        }
        const finalPrice = (eProduct.units + units) * eProduct.price;
        const finalMargin = (eProduct.units + units) * eProduct.margin;
        const updatedProduct = {
          ...eProduct,
          units: eProduct.units + units,
          finalPrice: finalPrice,
          finalMargin: finalMargin,
        };

        newCart[indexOfElement] = updatedProduct;
        state.items = newCart;

        state.totalPrice =
          Math.round(
            (parseFloat(state.totalPrice) + parseFloat(action.payload.price)) *
              100
          ) / 100;
        state.itemCount = state.itemCount + action.payload.units;
        state.totalTaxes =
          state.totalTaxes === 0
            ? isNaN(parseFloat(action.payload.totalTaxes))
              ? 0
              : action.payload.totalTaxes
            : state.totalTaxes;
        state.totalTaxAmount = (
          state.totalPrice * state.totalTaxes +
          state.totalPrice
        ).toFixed(2);
        // console.log(state);
      } else {
        state.items = [...state.items, action.payload];
        state.totalPrice =
          Math.round(
            (parseFloat(state.totalPrice) + parseFloat(action.payload.price)) *
              100
          ) / 100;

        state.itemCount = state.itemCount + action.payload.units;
        state.totalTaxes =
          state.totalTaxes === 0
            ? isNaN(parseFloat(action.payload.totalTaxes))
              ? 0
              : action.payload.totalTaxes
            : state.totalTaxes;
        state.totalTaxAmount = (
          state.totalPrice * state.totalTaxes +
          state.totalPrice
        ).toFixed(2);

        // console.log(state);
      }
    },
    minusFromCart: (state, action) => {
      const indexDec = state.items.findIndex(
        (key) => key.id === action.payload.id
      );
      const unit = 1;
      const newCart = state.items.slice();
      const eProduct = newCart[indexDec];
      if (eProduct.units > 1) {
        const finalPrice = (eProduct.units - unit) * eProduct.price;
        const finalMargin = (eProduct.units - unit) * eProduct.margin;
        // const finalMargin = (eProduct.units + units) * eProduct.margin;
        const updatedProduct = {
          ...eProduct,
          units: eProduct.units - unit,
          finalPrice: finalPrice,
          finalMargin: finalMargin,
        };
        newCart[indexDec] = updatedProduct;
        state.items = newCart;
        state.totalPrice =
          Math.round(
            (parseFloat(state.totalPrice) - parseFloat(action.payload.price)) *
              100
          ) / 100;
        state.itemCount =
          state.itemCount > 0 ? state.itemCount - action.payload.units : 0;
        state.totalTaxes =
          state.totalTaxes === 0
            ? isNaN(parseFloat(action.payload.totalTaxes))
              ? 0
              : action.payload.totalTaxes
            : state.totalTaxes;
        state.totalTaxAmount = (
          state.totalPrice * state.totalTaxes +
          state.totalPrice
        ).toFixed(2);
      } else {
        const existingCart = state.items.slice();
        const newCartItems = existingCart.filter(
          (itemKey) => itemKey.id !== action.payload.id
        );
        state.items = newCartItems;
        state.totalPrice =
          Math.round(
            (parseFloat(state.totalPrice) - parseFloat(action.payload.price)) *
              100
          ) / 100;
        state.itemCount =
          state.itemCount > 0 ? state.itemCount - action.payload.units : 0;
        state.totalTaxes =
          state.totalTaxes === 0
            ? isNaN(parseFloat(action.payload.totalTaxes))
              ? 0
              : action.payload.totalTaxes
            : state.totalTaxes;
        state.totalTaxAmount = (
          state.totalPrice * state.totalTaxes +
          state.totalPrice
        ).toFixed(2);
      }
    },
    deleteFromCart: (state, action) => {
      const existingCart = state.items.slice();
      const newCartItems = existingCart.filter(
        (itemKey) => itemKey.id !== action.payload.id
      );
      state.items = newCartItems;
    },
    emptyCart: (state, action) => {
      state.items = [];
      state.totalPrice = 0;
      state.itemCount = 0;
      state.totalTaxes = 0;
      state.totalTaxAmount = 0;
      state.resturantId = action.payload.resturantId;
      state.mode = "";
    },
    adduserInfo: (state, action) => {
      state.userInfo._id = action.payload.id;
      state.userInfo.name = action.payload.name;
      state.userInfo.email = action.payload.email;
      state.userInfo.isAdmin = action.payload.isAdmin;
      state.userInfo.token = action.payload.token;
    },
    addTaxInfo: (state, action) => {
      state.totalTaxes = action.payload.totalTaxes;
    },
    addGuestInfo: (state, action) => {
      state.name = action.payload.name;
      state.mobile = action.payload.mobile;
    },
  },
});

export const {
  addToCart,
  deleteFromCart,
  minusFromCart,
  emptyCart,
  adduserInfo,
  addGuestInfo,
} = cartSlice.actions;

export default cartSlice.reducer;
