import React from "react";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Button,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import { format } from "date-fns";
import PrintIcon from "@mui/icons-material/Print";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderColor: "blue",
}));

const PrintScreen = () => {
  const location = useLocation();
  // const { items, totalTaxAmount } = useSelector((state) => state.cart);

  const printReceipt = () => {
    window.print();
  };
  return (
    <Container>
      <DrawerHeader />
      <div
        className="no-printme"
        style={{
          margin: 20,
        }}
      >
        <Button
          variant="contained"
          onClick={printReceipt}
          className="no-printme"
        >
          <PrintIcon />
          Print
        </Button>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 0 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DATE</strong> :
                        {format(new Date(), "dd-MMM-yyyy")}
                      </TableCell>
                      <TableCell>
                        <strong>PRIMECARE HOSPITAL, NANDYAL</strong>
                      </TableCell>
                      <TableCell>
                        <strong>NAME</strong> : {location.state.order.name}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <strong>ITEM NAME</strong>
                      </TableCell>
                      <TableCell>
                        <strong>PRICE</strong>
                      </TableCell>
                      <TableCell>
                        <strong>QUANTITY</strong>
                      </TableCell>
                      <TableCell>
                        <strong>TOTAL</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {location.state.order.orderItems.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.name}
                        </TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>{item.units}</TableCell>
                        <TableCell>
                          ₹ {Math.round(item.finalPrice.toFixed(2))}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <strong>TOTAL : </strong>
                      </TableCell>
                      <TableCell>
                        ₹{" "}
                        <strong>
                          {Math.round(
                            location.state.order.totalPrice.toFixed(2)
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PrintScreen;
