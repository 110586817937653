import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container, Button, Grid, TextField } from "@mui/material";

const ProductEditScreen = () => {
  const { products } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [margin, setMargin] = useState(0);
  const [units, setUnits] = useState(1);
  const [image, setImage] = useState("null");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState("");
  const [freetext1, setFreetext1] = useState("");
  const [freetext2, setFreetext2] = useState("");
  const [freetext3, setFreetext3] = useState("");
  const [freetext4, setFreetext4] = useState("");
  const [freetext5, setFreetext5] = useState("");
  const [location, setLocation] = useState("");
  const [loaded, setLoaded] = useState(true);

  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loaded) {
      let tempProduct = products.find((p) => p._id === id);
      setProduct(tempProduct);
      setName(tempProduct.name);
      setImage(tempProduct.image);
      setBrand(tempProduct.brand);
      setCategory(tempProduct.category);
      setDescription(tempProduct.description);
      setCountInStock(tempProduct.countInStock);
      setFreetext1(tempProduct.freetext1);
      setFreetext2(tempProduct.freetext2);
      setFreetext3(tempProduct.freetext3);
      setFreetext4(tempProduct.freetext4);
      setFreetext5(tempProduct.freetext5);
      setLocation(tempProduct.location);
      setPrice(tempProduct.price);
      setMargin(tempProduct.margin);
      setActualPrice(tempProduct.actualPrice);
      setUnits(tempProduct.units);
      setTotalProductPrice(tempProduct.totalProductPrice);
    } else {
      setPrice((totalProductPrice / units).toFixed(2));
      setMargin(((totalProductPrice - actualPrice) / units).toFixed(2));
    }

    setIsLoading(false);
    setLoaded(false);
    // };

    // if (loaded) {
    //   loadTemp();
    // }
  }, [totalProductPrice, units, actualPrice, id, product, products, loaded]);
  // id, product, products,
  // setTotalProductPrice(tempProduct.totalPrice);
  const SubmitHandle = async (e) => {
    // e.preventdefault();
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localToken;
    if (loginStatus) {
      localToken = loginStatus;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localToken}`,
      },
    };
    const response = await axios.put(
      `/api/products/${id}`,
      {
        name: name.toUpperCase(),
        totalProductPrice: totalProductPrice,
        units: units,
        actualPrice: actualPrice,
        margin: margin,
        image: image,
        brand: brand,
        category: category,
        description: description,
        price: price,
        countInStock: countInStock,
        freetext1: freetext1,
        freetext2: freetext2,
        freetext3: freetext3,
        freetext4: freetext4,
        freetext5: freetext5,
        location: location,
      },
      config
    );
    // console.log(response.data);

    navigate("/admin/productlist", { replace: true });
    navigate(0);
    return response?.data;
  };

  return (
    <Container>
      <DrawerHeader />
      <Button variant="outlined" style={{ margin: 5 }}>
        <Link to="/admin/productlist">Go Back</Link>
      </Button>

      <h4>Update Product</h4>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="name"
              variant="outlined"
              label="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              id="price"
              variant="outlined"
              label="Enter price [Total Price/units][Read Only]"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="totalprice"
              label="Enter Total price"
              variant="outlined"
              value={totalProductPrice}
              onChange={(e) => setTotalProductPrice(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="units"
              label="Enter Total units"
              variant="outlined"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="actualprice"
              label="Enter Actual Price"
              variant="outlined"
              value={actualPrice}
              onChange={(e) => setActualPrice(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              id="margin"
              label="Enter Margin [(Total price - Actual price)/units][Read Only]"
              variant="outlined"
              value={margin}
              onChange={(e) => setMargin(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="image"
              label="Enter Image Url or null"
              variant="outlined"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="category"
              label="Enter Category"
              variant="outlined"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="description"
              label="Enter Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="freetext1"
              label="Enter Free Text1"
              variant="outlined"
              value={freetext1}
              onChange={(e) => setFreetext1(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="freetext2"
              label="Enter Free Text2"
              variant="outlined"
              value={freetext2}
              onChange={(e) => setFreetext2(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="freetext3"
              label="Enter Free Text3"
              variant="outlined"
              value={freetext3}
              onChange={(e) => setFreetext3(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="freetext4"
              label="Enter Free Text4"
              variant="outlined"
              value={freetext4}
              onChange={(e) => setFreetext4(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="freetext5"
              label="Enter Free Text5"
              variant="outlined"
              value={freetext5}
              onChange={(e) => setFreetext5(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="location"
              label="Enter location"
              variant="outlined"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              id="countInStock"
              label="Count in Stock"
              variant="outlined"
              value={countInStock}
              onChange={(e) => setCountInStock(e.target.value)}
              style={{
                margin: 10,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ marginBottom: 50 }}>
            <center>
              <Button variant="contained" onClick={SubmitHandle}>
                Update
              </Button>
            </center>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ProductEditScreen;
