import { configureStore } from "@reduxjs/toolkit";
import productsSlice from "../redux/Items/itemsSlice";
import cartSliceReducer from "../redux/Items/cartSlice";
import loginSlice from "../redux/Items/loginSlice";
import usersSlice from "../redux/Items/getUsersSlice";
export const store = configureStore({
  reducer: {
    products: productsSlice,
    cart: cartSliceReducer,
    login: loginSlice,
    users: usersSlice,
  },
});
