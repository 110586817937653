import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div>
        <center>
          <div style={{ fontSize: "285px", fontFamily: "sans-serif" }}>404</div>
          <div style={{ fontSize: "50px", fontFamily: "sans-serif" }}>
            Not Found
          </div>
        </center>
      </div>
    </>
  );
};

export default PageNotFound;
