// import Header from "./components/Header";
// import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import { Container } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import UserListScreen from "./screens/UserListScreen";
import Cart from "./screens/Cart";
import LoginScreen from "./screens/LoginScreen";
import { fetchProducts } from "./redux/Items/itemsSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import StatsScreen from "./screens/StatsScreen";
import PageNotFound from "./screens/PageNotFound";
import SearchBox from "./components/SearchBox";
import StockListScreen from "./screens/StockListScreen";
import { PasswordChangeScreen } from "./screens/PasswordChangeScreen";
import AddUserScreen from "./screens/AddUserScreen";
import MiniDrawer from "./components/MiniDrawer";
import PrintScreen from "./screens/PrintScreen";
import StockDetails from "./screens/StockDetails";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  return (
    <BrowserRouter>
      {/* <Header /> */}
      {/* <main className="py-3"> */}
      <MiniDrawer />
      <Container>
        <Routes>
          <Route path="/" element={<LoginScreen />}></Route>
          <Route path="/home" element={<HomeScreen />}></Route>

          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/login" element={<LoginScreen />}></Route>
          <Route path="/admin/userlist" element={<UserListScreen />}></Route>
          <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
          <Route path="/admin/productlist" element={<ProductListScreen />} />
          <Route
            path="/admin/product/:id/edit"
            element={<ProductEditScreen />}
          />
          <Route path="/admin/orderlist" element={<OrderListScreen />}></Route>
          <Route path="/admin/stats" element={<StatsScreen />}></Route>
          <Route path="/admin/stockdetails" element={<StockDetails />}></Route>
          <Route path="/admin/stocks" element={<StockListScreen />} />

          <Route path="/search/:keyword" element={<SearchBox />} />
          <Route path="/user/password" element={<PasswordChangeScreen />} />
          <Route path="/user/create" element={<AddUserScreen />} />
          <Route path="/print" element={<PrintScreen />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
      {/* </main> */}
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
