import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { fetchUsers } from "../redux/Items/getUsersSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import {
  Button,
  Container,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const UserListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userlist, loading, error } = useSelector((state) => state.users);

  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (loginStatus && jwt_decode(loginStatus).payload.role === "admin") {
      dispatch(fetchUsers());
    } else {
      navigate("/login", { replace: true });
    }
  }, [dispatch, navigate]);

  const deleteHandler = async (id) => {
    if (window.confirm("are you sure")) {
      const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
      // console.log("loginStatus : " + loginStatus);
      let localToken;
      if (loginStatus) {
        localToken = loginStatus;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };
      const response = await axios.delete(`/api/users/${id}`, config);
      console.log("data " + response.data);
      navigate(0);
      return response?.data;
    }
  };

  return (
    <Container>
      <DrawerHeader />

      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: 20,
        }}
      >
        <h4>Users</h4>
        <Button
          variant="contained"
          onClick={() => navigate("/user/create", { replace: true })}
        >
          <AddIcon />
          Add Users
        </Button>
      </div>

      {loading ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 0 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>ADMIN</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userlist.map((user, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user._id}
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>

                  <TableCell>
                    {user.role === "admin" ? (
                      <CheckIcon color="success" />
                    ) : (
                      <DangerousIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Link to={`/admin/user/${user._id}/edit`}>
                      <EditIcon color="primary" />
                    </Link>
                    <Button
                      variant="error"
                      onClick={() => deleteHandler(user._id)}
                    >
                      <DeleteIcon color="primary" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : error ? (
        <Message variant="error">{error}</Message>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default UserListScreen;
