import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";

const BASE_URL = "/api/users/tenant";

const initialState = {
  userlist: [],
  loading: false,
  error: "",
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
  let localToken, localTenant;
  if (loginStatus) {
    localToken = loginStatus;
    localTenant = jwt_decode(loginStatus).payload.tenant;
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localToken}`,
    },
  };
  const response = await axios.get(BASE_URL + `/${localTenant}`, config);
  // console.log(response.data);
  return response?.data;
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // ==> normal reducer functions go here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.loading = false;
        state.userlist = [];
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = true;
        state.userlist = state.userlist.concat(action.payload);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

export default usersSlice.reducer;
