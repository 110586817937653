import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Message from "../components/Message";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { TextField, Grid, Button } from "@mui/material";

export const PasswordChangeScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (password === confirmPassword) {
      setError(false);
    } else {
      setMessage("Password fields doesnot match");
      setVariant("error");
      setError(true);
    }
  }, [password, confirmPassword]);

  const SubmitHandle = async (e) => {
    if (password === "" || confirmPassword === "") {
      setMessage("Password fields cannot be empty");
      setVariant("error");
      setError(true);
      return;
    } else if (password !== confirmPassword) {
      setMessage("Password fields does not match");
      setVariant("error");
      setError(true);
      return;
    }

    try {
      const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
      let localToken;
      let id;
      if (loginStatus) {
        localToken = loginStatus;
        id = jwt_decode(loginStatus).payload.id;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };
      const response = await axios.put(
        `/api/users/password/${id}`,
        {
          password: password,
        },
        config
      );
      setPassword("");
      setConfirmPassword("");
      setSuccess(true);
      setVariant("success");
      setMessage(response.data.message);
      return response?.data;
    } catch (error) {
      setError(true);
      setVariant("error");
      setMessage(error);
    }
  };

  return (
    <>
      <DrawerHeader />
      <Link to="/">Go Back</Link>
      {isError ? <Message variant={variant}>{message}</Message> : <></>}
      {isSuccess ? <Message variant={variant}>{message}</Message> : <></>}
      <h1>
        <center>Update Password</center>
      </h1>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          margin: 20,
        }}
      >
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="password"
            value={password}
            label="Enter Password..."
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            // fullWidth
            style={{ margin: 5 }}
            type="password"
            id="outlined-basic1"
            variant="outlined"
            value={confirmPassword}
            label="Re Enter Password..."
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={SubmitHandle}>
            Update
          </Button>
        </Grid>
      </div>
    </>
  );
};
