import React, { useEffect, useState } from "react";
import Message from "../components/Message";
import Loader from "../components/Loader";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container, Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrderListScreen = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [startdate, setStartdate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [enddate, setEnddate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    const getOrders = async () => {
      try {
        const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
        let localToken, localTenant;
        if (loginStatus) {
          localToken = loginStatus;
          localTenant = jwt_decode(loginStatus).payload.tenant;
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
          },
        };
        const response = await axios.post(
          `/api/orders/limit/${localTenant}`,
          {
            startdate: startdate,
            enddate: enddate,
          },
          config
        );
        // console.log(response.data);
        setOrders(response.data.result);
        setIsLoading(true);
        return response?.data.result;
      } catch (error) {
        setError(error);
      }
    };

    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (
      loginStatus &&
      (jwt_decode(loginStatus).payload.role === "admin" ||
        jwt_decode(loginStatus).payload.role === "user")
    ) {
      getOrders();
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, startdate, enddate]);

  return (
    <Container>
      <DrawerHeader />
      <h4>ORDERS</h4>

      {loading ? (
        <>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              margin: 20,
            }}
          >
            <Grid item xs={12} md={6} style={{ margin: 5 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  value={startdate}
                  onChange={(newValue) => {
                    setStartdate(newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} style={{ margin: 5 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  value={enddate}
                  onChange={(newValue) => {
                    setEnddate(newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </div>

          <hr />

          {orders.map((order, index) => (
            <div key={index}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 0 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>NAME</StyledTableCell>
                          <StyledTableCell>NUMBER</StyledTableCell>
                          <StyledTableCell>PRICE</StyledTableCell>
                          <StyledTableCell>DATE</StyledTableCell>
                          {/* <StyledTableCell>BILLING USER</StyledTableCell> */}
                          <StyledTableCell>PRINT</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell>{order.name}</StyledTableCell>
                          <StyledTableCell>{order.customer}</StyledTableCell>
                          <StyledTableCell>{order.totalPrice}</StyledTableCell>
                          <StyledTableCell>{order.createdAt}</StyledTableCell>
                          {/* <StyledTableCell>{order.user.name}</StyledTableCell> */}
                          <StyledTableCell>
                            <Button
                              variant="contained"
                              key="print"
                              value="print"
                              onClick={() => {
                                navigate("/print", { state: { order: order } });
                              }}
                            >
                              <PrintIcon />
                              Print
                            </Button>
                          </StyledTableCell>
                          {/* <TableCell>
                            {order.createdAt.substring(0, 10)}
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 0 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>ITEM NAME</StyledTableCell>
                          <StyledTableCell>PRICE</StyledTableCell>
                          <StyledTableCell>UNITS</StyledTableCell>
                          <StyledTableCell>TOTAL</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.orderItems.map((item, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {item.name}
                            </StyledTableCell>
                            <StyledTableCell>{item.price}</StyledTableCell>
                            <StyledTableCell>{item.units}</StyledTableCell>
                            <StyledTableCell>{item.finalPrice}</StyledTableCell>
                            {/* <TableCell>
                            {order.createdAt.substring(0, 10)}
                          </TableCell> */}
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}

          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 0 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>CUSTOMER</TableCell>
                  <TableCell>PAYMENT METHOD</TableCell>
                  <TableCell>PRICE</TableCell>
                  <TableCell>ORDERED AT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {order.customer}
                    </TableCell>
                    <TableCell>{order.paymentMethod}</TableCell>
                    <TableCell>{order.totalPrice}</TableCell>
                    <TableCell>{order.createdAt.substring(0, 10)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </>
      ) : error ? (
        <Message variant="error">{error}</Message>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default OrderListScreen;
