import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Message from "../components/Message";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import {
  Container,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

const AddUserScreen = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const addUserToDb = async () => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localToken;
    let localTenant;

    if (loginStatus) {
      localToken = loginStatus;
      localTenant = jwt_decode(loginStatus).payload.tenant;
    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };

      const userResponse = await axios.post(
        `/api/users`,
        {
          name: userName,
          email: email,
          password: password,
          tenant: localTenant,
          role: role,
        },
        config
      );

      setUserName("");
      setPassword("");
      setEmail("");
      setSuccess(true);
      setVariant("info");
      setUserMessage(`Created User ${userResponse.data.name} Successfully`);
      const statusCode = userResponse.status;
      console.log("statusCode" + statusCode);
      return userResponse?.data;
    } catch (error) {
      console.log(error.response.data.message);
      setError(true);
      setVariant("error");
      setUserMessage(error.response.data.message);
    }
  };

  const SubmitHandle = () => {
    if (userName === "") {
      setError(true);
      setVariant("error");
      setUserMessage("Name cannot be Empty");
      return;
    } else if (email === "") {
      setError(true);
      setVariant("error");
      setUserMessage("Email Cannot be empty");
      return;
    } else if (password === "") {
      setError(true);
      setVariant("error");
      setUserMessage("password Cannot be empty");
      return;
    } else if (role === "") {
      setError(true);
      setVariant("error");
      setUserMessage("Role Cannot be empty");
      return;
    }

    addUserToDb();
  };

  return (
    <Container>
      <DrawerHeader />
      <Button variant="outlined" style={{ margin: 5 }}>
        <Link to="/admin/userlist">Go Back</Link>
      </Button>
      {isError ? <Message variant={variant}>{userMessage}</Message> : <></>}
      {isSuccess ? <Message variant={variant}>{userMessage}</Message> : <></>}
      <h4>
        <center>Add User</center>
      </h4>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          margin: 20,
        }}
      >
        <Grid item xs={12} md={6}>
          <TextField
            id="name"
            variant="outlined"
            type="name"
            placeholder="Enter Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            style={{
              margin: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="name"
            variant="outlined"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              margin: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="name"
            variant="outlined"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              margin: 10,
            }}
          />
        </Grid>
        <Select
          labelId="Role"
          id="role"
          label="Role"
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          sx={{ m: 1, minWidth: 220 }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="user">user</MenuItem>
          <MenuItem value="admin">admin</MenuItem>
        </Select>

        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={SubmitHandle}>
            Update
          </Button>
        </Grid>
      </div>
    </Container>
  );
};

export default AddUserScreen;
