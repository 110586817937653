import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Product } from "../components/Product";
import SearchBox from "./SearchBox";
import { addToCart, minusFromCart } from "../redux/Items/cartSlice";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Grid,
  Stack,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Pagination,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const DisplayTab = ({ cat }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { products } = useSelector((state) => state.products);
  const { items } = useSelector((state) => state.cart);
  let [productCat, setProductCat] = useState([]);
  let [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const handleChange = (event, value) => {
    // console.log("value : " + value);
    setCurrentPage(value);
  };
  useEffect(() => {
    if (cat === "Search") {
      // setProductCat(products);
      keyword === ""
        ? setProductCat(products.filter((item) => item.countInStock > 0))
        : setProductCat(
            products
              .filter((item) => item.countInStock > 0)
              .filter((item) => item.name.includes(keyword.toUpperCase()))
          );
    } else {
      let tempProduct = products.filter((item) => item.category === cat);
      setProductCat(tempProduct);
    }
  }, [cat, products, keyword]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productCat.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SearchBox setKeyword={setKeyword} />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Item>
          <Grid container item md={12} direction="column">
            {/* <Slide direction="up" in={true} mountOnEnter unmountOnExit> */}
            <Grid container>
              {currentProducts.map((product) => (
                <Product product={product} key={product._id} />
              ))}
            </Grid>
            {/* </Slide> */}
          </Grid>
        </Item>
        <Item>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 0 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ITEM</TableCell>
                  <TableCell>PRICE</TableCell>
                  <TableCell>QUANTITY</TableCell>
                  <TableCell>TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Button
                          key="one"
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              minusFromCart({
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                price: item.price,
                                image: item.image,
                                units: 1,
                                finalPrice: item.price,
                              })
                            );

                            enqueueSnackbar(
                              "Item : " + item.name + " Removed from Cart",
                              { variant: "error" }
                            );
                          }}
                        >
                          -
                        </Button>
                        <Button key="two" variant="outlined">
                          <Typography>
                            <strong> {item.units}</strong>
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          key="three"
                          onClick={() => {
                            dispatch(
                              addToCart({
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                price: item.price,
                                image: item.image,
                                units: 1,
                                finalPrice: item.price,
                              })
                            );

                            enqueueSnackbar(
                              "Item : " + item.name + " Added to Cart",
                              { variant: "success" }
                            );
                          }}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell align="right">
                      ₹ {Math.round(item.finalPrice.toFixed(2))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="outlined" style={{ margin: 10 }}>
            <Link to="/cart">Go To Cart</Link>
          </Button>
        </Item>
      </Stack>
      <Stack spacing={2}>
        {/* <Item>
          <Typography>Page: {currentPage}</Typography>
        </Item> */}
        <Item>
          <Pagination
            count={Math.ceil(productCat.length / productsPerPage)}
            page={currentPage}
            onChange={handleChange}
          />
        </Item>
      </Stack>
    </Box>
  );
};

export default DisplayTab;
