import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import DisplayTab from "../components/DisplayTab";
import { styled, useTheme } from "@mui/material/styles";

const HomeScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (!loginStatus) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const { loading, error } = useSelector((state) => state.products);

  return (
    <>
      {!loading ? (
        <Loader />
      ) : error !== "" ? (
        <Message variant="error">{error}</Message>
      ) : (
        <>
          <DrawerHeader />
          <DisplayTab cat="Search" />
        </>
      )}
    </>
  );
};

export default HomeScreen;
