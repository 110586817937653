import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/Items/cartSlice";

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";

export const Product = ({ product }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ maxWidth: 300, margin: 1, padding: 3 }}>
        <CardHeader subheader={product.name} />

        {product.image === "null" ? (
          <></>
        ) : (
          <CardMedia
            component="img"
            height="194"
            image={product.image}
            alt="Image"
          />
        )}

        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Current Stock : {product.countInStock}
          </Typography>
          {product.freetext1 === "text" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.freetext1}
            </Typography>
          )}
          {product.freetext2 === "text" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.freetext2}
            </Typography>
          )}
          {product.freetext3 === "text" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.freetext3}
            </Typography>
          )}
          {product.freetext4 === "text" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.freetext4}
            </Typography>
          )}
          {product.freetext5 === "text" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.freetext5}
            </Typography>
          )}
          {product.location === "location" ? (
            <></>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {product.location}
            </Typography>
          )}

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <strong>₹ {product.price}</strong>
          </Typography>
        </CardContent>
        {/* <CardActions disableSpacing> */}
        <Button
          disabled={product.countInStock <= 0}
          variant="contained"
          onClick={() => {
            dispatch(
              addToCart({
                id: product._id,
                name: product.name,
                description: product.description,
                price: product.price,
                image: product.image,
                units: 1,
                finalPrice: product.price,
                productActualQty: product.countInStock,
                margin: product.margin,
                finalMargin: product.margin,
              })
            );
            enqueueSnackbar(product.name + " Added to Cart", {
              variant: "success",
            });
          }}
        >
          Add To Cart
        </Button>

        {/* </CardActions> */}
      </Card>
    </Grid>
  );
};
