import React from "react";
// import { Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
const Loader = () => {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> */}
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
      <Skeleton variant="rectangular" width={1000} height={60} />
    </Stack>
  );
};

export default Loader;
