import React, { useEffect, useState } from "react";
import Message from "../components/Message";
import Loader from "../components/Loader";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";

import { Container, Button, Grid, TextField, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StockListScreen = () => {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productLoading, setIsProductLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [qty, setQty] = useState(0);

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [isError, setErrorFlag] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filterStocks, setFilterStocks] = useState({});
  const [productsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    // console.log("value : " + value);
    setCurrentPage(value);
  };

  useEffect(() => {
    const getStocks = async () => {
      try {
        const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
        let localToken, localTenant;
        if (loginStatus) {
          localToken = loginStatus;
          localTenant = jwt_decode(loginStatus).payload.tenant;
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
          },
        };
        const response = await axios.get(
          `/api/stocks/data/${localTenant}`,
          config
        );
        // console.log(response.data);
        setStocks(response.data);
        setIsLoading(true);
        return response?.data;
      } catch (error) {
        setError(error);
      }
    };

    const getProducts = async () => {
      try {
        const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
        let localToken, localTenant;
        if (loginStatus) {
          localToken = loginStatus;
          localTenant = jwt_decode(loginStatus).payload.tenant;
        } else {
          localTenant = "testtenant";
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
          },
        };
        const response = await axios.get(
          `/api/products/tenant/${localTenant}`,
          config
        );
        // console.log(response.data);
        setProducts(response.data);
        setIsProductLoading(true);
        return response?.data;
      } catch (error) {
        setError(error);
      }
    };

    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (
      loginStatus &&
      (jwt_decode(loginStatus).payload.role === "admin" ||
        jwt_decode(loginStatus).payload.role === "user")
    ) {
      if (!dataLoading) {
        getStocks();
        getProducts();
        setDataLoading(true);
      }
      if (keyword === "") {
        setFilterStocks(stocks);
      } else {
        setFilterStocks(
          stocks.filter((stock) => stock.name.includes(keyword.toUpperCase()))
        );
        // setFilterProducts();
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, keyword, stocks, dataLoading]);

  let currentProducts;
  if (loading) {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    currentProducts = filterStocks.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );
  }

  const SubmitHandle = async () => {
    if (name && qty && id) {
      try {
        const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
        let localToken, localTenant;
        if (loginStatus) {
          localToken = loginStatus;
          localTenant = jwt_decode(loginStatus).payload.tenant;
        } else {
          localTenant = "testtenant";
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
          },
        };
        const response = await axios.post(
          `/api/stocks/${localTenant}`,
          {
            name: name,
            id: id,
            qty: qty,
          },
          config
        );
        // console.log(response.data);
        navigate(0);
        // return response?.data;
        // setProducts(response.data);
        // setIsProductLoading(true);
        return response?.data;
      } catch (error) {
        setError(error);
      }
    } else {
      window.confirm("Enter valid value");
    }
  };

  const deleteHandler = async (id) => {
    try {
      if (window.confirm("are you sure")) {
        const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
        let localToken;
        if (loginStatus) {
          localToken = loginStatus;
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
          },
        };
        const response = await axios.delete(`/api/stocks/data/${id}`, config);
        console.log("response.status : " + response.status);
        // if(response.status)
        console.log("response : " + JSON.stringify(response.data));
        navigate(0);
        return response?.data;
      }
    } catch (error) {
      console.log("error : " + JSON.stringify(error));
      console.log("error.status : " + error.status);

      setErrorFlag(true);
      setVariant("error");
      setMessage(
        "You cannot delete the current record as it doesn't have enough stock"
      );

      // setMessage();
    }
  };

  return (
    <Container>
      <DrawerHeader />
      <h4>INVENTORY</h4>
      {isError ? <Message variant={variant}>{message}</Message> : <></>}
      {loading && productLoading ? (
        <>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
              margin: 20,
            }}
          >
            <Grid item xs={12} md={6} style={{ marginRight: 10 }}>
              <TextField
                fullWidth
                id="filterStocks"
                label="Filter Stocks"
                variant="outlined"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Grid>

            <Autocomplete
              inputValue={name}
              onInputChange={(event, newInputValue) => {
                setName(newInputValue);
                console.log("newInputValue : " + newInputValue);
                products.forEach((product) => {
                  if (product.name === newInputValue) {
                    setId(product._id);
                  }
                });
              }}
              id="product-select"
              sx={{ width: 300 }}
              options={products}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Product"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            <Grid item xs={12} md={6}>
              <TextField
                id="qty"
                variant="outlined"
                type="number"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                style={{
                  margin: 10,
                }}
              />
            </Grid>
            <Button variant="contained" onClick={SubmitHandle}>
              Add
            </Button>
          </div>

          <hr />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 0 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>NAME</StyledTableCell>
                  <StyledTableCell>QUANTITY</StyledTableCell>
                  <StyledTableCell>CREATED</StyledTableCell>
                  <StyledTableCell>UPDATED</StyledTableCell>
                  <StyledTableCell>ACTIONS</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProducts.map((stock, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {stock.name}
                    </StyledTableCell>
                    <StyledTableCell>{stock.qty}</StyledTableCell>
                    <StyledTableCell>
                      {stock.createdAt.substring(0, 10)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {stock.updatedAt.substring(0, 10)}
                    </StyledTableCell>
                    {jwt_decode(JSON.parse(localStorage.getItem("userInfo")))
                      .payload.role === "admin" ? (
                      <StyledTableCell>
                        <Button
                          variant="error"
                          onClick={() => deleteHandler(stock._id)}
                        >
                          <DeleteIcon color="primary" />
                        </Button>
                      </StyledTableCell>
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ margin: 8 }}>
            {filterStocks.length >= 10 ? (
              <Pagination
                count={Math.ceil(filterStocks.length / productsPerPage)}
                page={currentPage}
                onChange={handleChange}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      ) : error ? (
        <Message variant="error">{error}</Message>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default StockListScreen;
