import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container, Stack } from "@mui/material";
import RenderCard from "../components/RenderCard";
import Message from "../components/Message";
import Loader from "../components/Loader";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";

import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StockDetails = () => {
  let currStockData = [];
  const [error, setError] = useState("");
  const [currentStockValue, setCurrentStockValue] = useState([]);
  const [loadStockValue, setLoadStockValue] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [loadingStock, setLoadingStock] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [loadingSales, setLoadingSales] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (loginStatus && jwt_decode(loginStatus).payload.role === "admin") {
      const getStock = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/stocks/${localTenant}`,
            config
          );

          setStockData(response.data.result);
          // console.log(
          //   "response.data.result : " + JSON.stringify(response.data.result)
          // );
          setLoadingStock(true);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };

      const getSales = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/orders/sales/${localTenant}`,
            config
          );

          setSalesData(response.data.result);

          setLoadingSales(true);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };
      const getStockValue = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/products/currentproductvalue/${localTenant}`,
            config
          );

          setCurrentStockValue(response.data.result);
          // console.log("0 : " + currentStockValue);
          setLoadStockValue(true);
          // console.log("1 : " + currentStockValue);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };
      getStockValue();
      getStock();
      getSales();
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  if (loadingSales && loadingStock) {
    salesData.forEach((product) => {
      stockData.forEach((stock) => {
        if (product._id === stock._id) {
          currStockData.push({
            name: product._id,
            totalStock: stock.totalStock,
            totalSales: product.items,
            currentStock: stock.totalStock - product.items,
          });
        }
      });
    });
    // console.log("currStockData : " + JSON.stringify(currStockData));
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      {loadStockValue ? (
        <Container>
          <DrawerHeader />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <RenderCard
              name="Current Stock Value"
              value={"₹" + JSON.stringify(currentStockValue[0].totalValue)}
              imagePath="Income.png"
            />
          </Stack>
          <hr />
          <div>Stock Details</div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Item>
                  {loadingSales && loadingStock ? (
                    <div>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 0 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>NAME</StyledTableCell>
                              <StyledTableCell>TOTAL STOCK</StyledTableCell>
                              <StyledTableCell>TOTAL SALES</StyledTableCell>
                              <StyledTableCell>CURRENT STOCK</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currStockData.map((stock, index) => (
                              <StyledTableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  {stock.name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {stock.totalStock}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {stock.totalSales}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {stock.currentStock}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : error ? (
                    <Message variant="error">{error}</Message>
                  ) : (
                    <Loader />
                  )}
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default StockDetails;
