import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import SideMenuList from "./SideMenuList";

import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import data from "../versionNumber.json";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { itemCount } = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    navigate("/", { replace: true });
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>
      {loginStatus ? (
        <div className="no-printme">
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap
                  component={Link}
                  to="/home"
                  sx={{
                    mr: 2,
                    display: { md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {jwt_decode(loginStatus).payload.tenant}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                  <IconButton
                    size="large"
                    aria-label="show new items"
                    color="inherit"
                    onClick={() => {
                      navigate("/cart", { replace: true });
                    }}
                  >
                    <Badge badgeContent={itemCount} color="error">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="logout"
                    color="inherit"
                    onClick={logoutHandler}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <p
                  style={{
                    fontSize: 12,
                    marginRight: 40,
                    fontWeight: "bold",
                  }}
                >
                  Version : {data.version}
                </p>

                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <>
                <Box onClick={handleDrawerClose}>
                  <List>
                    <SideMenuList
                      open={open}
                      name={"Cart"}
                      toGoLink={"/cart"}
                      setOpen={setOpen}
                    />

                    {loginStatus &&
                      jwt_decode(loginStatus).payload.role === "admin" && (
                        <>
                          <SideMenuList
                            open={open}
                            name={"User"}
                            toGoLink={"/admin/userlist"}
                          />
                          <SideMenuList
                            open={open}
                            name={"Stats"}
                            toGoLink={"/admin/stats"}
                          />
                          <SideMenuList
                            open={open}
                            name={"Stocks Details"}
                            toGoLink={"/admin/stockdetails"}
                          />
                        </>
                      )}

                    <SideMenuList
                      open={open}
                      name={"Products"}
                      toGoLink={"/admin/productlist"}
                    />
                    <SideMenuList
                      open={open}
                      name={"Orders"}
                      toGoLink={"/admin/orderlist"}
                    />

                    <SideMenuList
                      open={open}
                      name={"Stocks"}
                      toGoLink={"/admin/stocks"}
                    />

                    <SideMenuList
                      open={open}
                      name={"Change Password"}
                      toGoLink={"/user/password"}
                    />
                  </List>
                </Box>
              </>
            </Drawer>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
