import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, minusFromCart, emptyCart } from "../redux/Items/cartSlice";
import { useSnackbar } from "notistack";

import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container, Grid, TextField, Stack, Typography } from "@mui/material";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Cart = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { items, itemCount, totalTaxAmount } = useSelector(
    (state) => state.cart
  );
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState("");
  const [orderData, setOrderData] = useState({});
  const [radioValue, setRadioValue] = useState("CASH");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (!loginStatus) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const handleCheckout = () => {
    createOrder();
  };

  const createOrder = async () => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localToken, localTenant;
    if (loginStatus) {
      localToken = loginStatus;
      localTenant = jwt_decode(loginStatus).payload.tenant;
    }
    // console.log(
    //   "jwt_decode(loginStatus).payload.id" + jwt_decode(loginStatus).payload.id
    // );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };

      const { data } = await axios.post(
        `/api/orders/${localTenant}`,
        {
          user: jwt_decode(loginStatus).payload.id,
          orderItems: items,
          paymentMethod: radioValue,
          totalPrice: Math.round(totalTaxAmount),
          customer: mobile,
          name: name,
        },
        config
      );
      console.log("data : " + JSON.stringify(items));
      console.log("data : " + JSON.stringify(data));
      setOrderData(data);
      dispatch(emptyCart({}));
      navigate(0);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
      console.log("error " + error.response.data.message);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderColor: "blue",
  }));

  return (
    <>
      <Container>
        <DrawerHeader />
        <h4>Shopping Cart</h4>
        {isError !== "" ? <Message variant="error">{isError}</Message> : <></>}
        {isSuccess ? (
          <Message>
            Your Order is successful with order ID : {orderData._id}
          </Message>
        ) : (
          <></>
        )}
        {items.length === 0 ? (
          <Message>
            Your Cart is Empty <Link to="/">Go Back</Link>
          </Message>
        ) : (
          <>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                margin: 10,
              }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="mobile"
                  type="number"
                  label="Mobile Number"
                  variant="outlined"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={{
                    margin: 10,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="name"
                  type="text"
                  label="Enter Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    margin: 10,
                  }}
                />
              </Grid>
            </div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Item>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 0 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>ITEM</TableCell>
                        <TableCell>PRICE</TableCell>
                        <TableCell>QUANTITY</TableCell>
                        <TableCell>TOTAL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.name}
                          </TableCell>
                          <TableCell>{item.price}</TableCell>
                          <TableCell>
                            <ButtonGroup
                              size="small"
                              aria-label="small button group"
                            >
                              <Button
                                key="one"
                                variant="contained"
                                onClick={() => {
                                  dispatch(
                                    minusFromCart({
                                      id: item.id,
                                      name: item.name,
                                      description: item.description,
                                      price: item.price,
                                      image: item.image,
                                      units: 1,
                                      finalPrice: item.price,
                                    })
                                  );
                                  enqueueSnackbar(
                                    "Item : " +
                                      item.name +
                                      " Removed from Cart",
                                    { variant: "error" }
                                  );
                                }}
                              >
                                -
                              </Button>
                              <Button key="two" variant="outlined">
                                <Typography>
                                  <strong> {item.units}</strong>
                                </Typography>
                              </Button>
                              <Button
                                key="three"
                                variant="contained"
                                onClick={() => {
                                  dispatch(
                                    addToCart({
                                      id: item.id,
                                      name: item.name,
                                      description: item.description,
                                      price: item.price,
                                      image: item.image,
                                      units: 1,
                                      finalPrice: item.price,
                                    })
                                  );
                                  enqueueSnackbar(
                                    "Item : " + item.name + " Added to Cart",
                                    { variant: "success" }
                                  );
                                }}
                              >
                                +
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                          <TableCell>
                            ₹ {Math.round(item.finalPrice.toFixed(2))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item>
                <Grid
                  container
                  display="flex"
                  justifyContent="flex-start"
                  item
                  xs={12}
                  md={6}
                >
                  <div>SubTotal : ({itemCount}) Items</div>
                  <div>
                    Round Off Amount :{" "}
                    {(Math.round(totalTaxAmount) - totalTaxAmount).toFixed(2)}
                  </div>

                  <div>Final Amount : {Math.round(totalTaxAmount)} </div>
                </Grid>
                <hr />
                <Grid
                  container
                  display="flex"
                  justifyContent="flex-start"
                  item
                  xs={12}
                  md={6}
                >
                  <ButtonGroup size="small" aria-label="small button group">
                    <Button
                      size="small"
                      key="four"
                      value="CASH"
                      onClick={(e) => setRadioValue(e.currentTarget.value)}
                      variant={radioValue === "CASH" ? "contained" : "outlined"}
                    >
                      CASH
                    </Button>
                    <Button
                      key="five"
                      value="CARD"
                      variant={radioValue === "CARD" ? "contained" : "outlined"}
                      onClick={(e) => setRadioValue(e.currentTarget.value)}
                    >
                      CARD
                    </Button>
                    <Button
                      key="six"
                      value="QRCODE"
                      variant={
                        radioValue === "QRCODE" ? "contained" : "outlined"
                      }
                      onClick={(e) => setRadioValue(e.currentTarget.value)}
                    >
                      QRCODE
                    </Button>
                  </ButtonGroup>
                </Grid>
                <hr />
                <Grid
                  container
                  display="flex"
                  justifyContent="flex-start"
                  item
                  xs={12}
                  md={6}
                >
                  <Button
                    variant="contained"
                    key="seven"
                    value="Complete Billing"
                    disabled={items.length === 0}
                    onClick={handleCheckout}
                  >
                    Complete Billing
                  </Button>
                </Grid>
                {/* <hr />
                <Grid
                  container
                  display="flex"
                  justifyContent="flex-start"
                  item
                  xs={12}
                  md={6}
                >
                  <Button
                    variant="contained"
                    key="print"
                    value="print"
                    disabled={items.length === 0}
                    onClick={() => {
                      navigate("/print", { state: { name: name } });
                    }}
                  >
                    Print
                  </Button>
                </Grid> */}
                <hr />
                <Grid
                  container
                  display="flex"
                  justifyContent="flex-start"
                  item
                  xs={12}
                  md={6}
                >
                  <Button
                    key="eight"
                    variant="contained"
                    value="Empty Cart"
                    disabled={items.length === 0}
                    onClick={() => {
                      dispatch(emptyCart({}));
                    }}
                  >
                    Empty Cart
                  </Button>
                </Grid>
              </Item>
            </Stack>
          </>
        )}
      </Container>
    </>
  );
};

export default Cart;
