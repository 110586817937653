import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import RenderCard from "../components/RenderCard";

import { Bar, Line } from "react-chartjs-2";

import Message from "../components/Message";
import Loader from "../components/Loader";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { startOfMonth, endOfMonth } from "date-fns";

import { DrawerHeader } from "../components/DrawerHeaderSpace";
import { Container, Stack } from "@mui/material";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

ChartJS.register(
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StatsScreen = () => {
  //   const dispatch = useDispatch();
  // setError
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCust, setLoadingCust] = useState(false);
  const [loadingWeeklyStats, setLoadingWeeklyStats] = useState(false);
  const [loadingMonthlyStats, setLoadingMonthlyStats] = useState(false);
  const [startdate, setStartdate] = useState(
    startOfMonth(new Date()).toISOString().slice(0, 10)
  );
  const [enddate, setEnddate] = useState(
    endOfMonth(new Date()).toISOString().slice(0, 10)
  );
  const [statsData, setStatsData] = useState([]);
  const [totalSalesData, setTotalSalesData] = useState([]);
  const [custSpendData, setCustSpendData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  const [amount, setAmount] = useState(0);
  let weeklyIncomeMoney = 0;
  let monthlyIncomeMoney = 0;
  const navigate = useNavigate();

  const colorSchema = [
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const colorBGSchema = [
    "rgba(153, 102, 255, 1)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];

  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (loginStatus && jwt_decode(loginStatus).payload.role === "admin") {
      const getStats = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken, localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/orders/monthly/margin/${localTenant}`,

            config
          );
          // console.log("Margin" + response.data.result);
          setStatsData(response.data.result);

          if (response.data.result.length > 5) {
            const salesData = JSON.parse(JSON.stringify(response.data.result));
            const finalSalesData = salesData.slice(0, 5);
            // setCustSpendData(finalCustomerData);
            setTotalSalesData(finalSalesData);
          } else {
            setTotalSalesData(response.data.result);
            // setCustSpendData(response.data.result);
          }

          setLoading(true);
          return response?.data;
        } catch (err) {
          setError(err);
        }
      };
      const getCustStats = async () => {
        // console.log(
        //   "startdate : " +
        //     startdate +
        //     " endDate : " +
        //     enddate +
        //     " amount : " +
        //     amount
        // );
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.post(
            `/api/orders/stats/customer/${localTenant}`,
            {
              startdate: startdate,
              enddate: enddate,
              amount: parseInt(amount),
            },
            config
          );
          // console.log(
          //   "custSpendData : " + JSON.stringify(response.data.result)
          // );

          // response.data.result.map((item) => {
          //   setCustData([...item._id]);
          //   setAmountData([...item.items]);
          // });
          // setCustDataGraph = [...custData, ...amountData];

          if (response.data.count > 5) {
            const customerData = JSON.parse(
              JSON.stringify(response.data.result)
            );
            const finalCustomerData = customerData.slice(0, 5);
            setCustSpendData(finalCustomerData);
          } else {
            setCustSpendData(response.data.result);
          }

          // console.log("custSpendData2 : " + JSON.stringify(custSpendData));
          setLoadingCust(true);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };

      const getSalesByWeekly = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/orders/weekly/${localTenant}`,
            config
          );

          setWeeklyData(response.data.result);

          setLoadingWeeklyStats(true);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };

      const getSalesByMonthly = async () => {
        try {
          const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
          let localToken;
          let localTenant;
          if (loginStatus) {
            localToken = loginStatus;
            localTenant = jwt_decode(loginStatus).payload.tenant;
          }

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localToken}`,
            },
          };
          const response = await axios.get(
            `/api/orders/monthly/${localTenant}`,
            config
          );

          setMonthlyData(response.data.result);

          setLoadingMonthlyStats(true);
          return response?.data;
        } catch (err) {
          setError(err);
          console.log(err);
        }
      };

      getStats();
      getCustStats();
      getSalesByWeekly();
      getSalesByMonthly();
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, startdate, enddate, amount]);

  if (loadingWeeklyStats) {
    const sum = (data) => {
      let total = 0;
      data.forEach((item) => {
        total += item.income;
      });
      return total;
    };
    weeklyIncomeMoney = sum(weeklyData);
  }

  if (loadingMonthlyStats) {
    const sum = (data) => {
      let total = 0;
      data.forEach((item) => {
        total += item.income;
      });
      return total;
    };
    monthlyIncomeMoney = sum(monthlyData);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Container>
      <DrawerHeader />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <RenderCard
          name="Monthly Sales"
          value={"₹" + monthlyIncomeMoney}
          imagePath="Income.png"
        />
        <RenderCard
          name="Weekly Sales"
          value={"₹" + weeklyIncomeMoney}
          imagePath="Income.png"
        />
      </Stack>
      <hr />
      <Box sx={{ width: "100%" }}>
        <Grid
          direction={{ xs: "column", sm: "row" }}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <Item>
              <Line
                data={{
                  labels: weeklyData.map((name) => name._id.substring(0, 10)),
                  datasets: [
                    {
                      label: "Sales By Date",
                      data: weeklyData.map((name) => String(name.income)),
                      backgroundColor: colorSchema,
                      borderColor: colorBGSchema,
                    },
                  ],
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Bar
                data={{
                  labels: weeklyData.map((name) => name._id.substring(0, 10)),
                  datasets: [
                    {
                      label: "Sales By Date",
                      data: weeklyData.map((name) => String(name.income)),
                      backgroundColor: colorBGSchema,
                      borderColor: colorSchema,
                    },
                  ],
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Line
                data={{
                  labels: monthlyData.map((name) => name._id.substring(0, 10)),
                  datasets: [
                    {
                      label: "Sales By Date",
                      data: monthlyData.map((name) => String(name.income)),
                      backgroundColor: colorSchema,
                      borderColor: colorBGSchema,
                    },
                  ],
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Bar
                data={{
                  labels: statsData.map((name) => name._id.substring(0, 10)),
                  datasets: [
                    {
                      label: "margin",
                      data: statsData.map((name) => String(name.margin)),
                      backgroundColor: ["rgba(54, 162, 235, 1)"],
                      borderColor: ["rgba(54, 162, 235, 1)"],
                    },
                    {
                      label: "Total sales",
                      data: statsData.map((name) => String(name.price)),
                      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                      borderColor: ["rgba(255, 99, 132, 0.2)"],
                    },
                    {
                      label: "Sales Count",
                      data: statsData.map((name) => String(name.itemCount)),
                      backgroundColor: ["rgba(255, 206, 86, 1)"],
                      borderColor: ["rgba(255, 206, 86, 1)"],
                    },
                  ],
                }}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <hr />
      <Box sx={{ width: "100%" }}>
        <Grid
          direction={{ xs: "column", sm: "row" }}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <Item>
              {loadingCust ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 0 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>CUSTOMER NAME</StyledTableCell>
                          <StyledTableCell>AMOUNT</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {custSpendData.map((stat, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {stat._id}
                            </StyledTableCell>
                            <StyledTableCell>{stat.spent}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : error ? (
                <Message variant="error">{error}</Message>
              ) : (
                <Loader />
              )}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              {loading ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 0 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>NAME</StyledTableCell>
                          <StyledTableCell>SALES</StyledTableCell>
                          <StyledTableCell>MARGIN</StyledTableCell>
                          <StyledTableCell>ITEM COUNT</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {totalSalesData.map((stat, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {stat._id}
                            </StyledTableCell>
                            <StyledTableCell>{stat.price}</StyledTableCell>
                            <StyledTableCell>{stat.margin}</StyledTableCell>
                            <StyledTableCell>{stat.itemCount}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : error ? (
                <Message variant="error">{error}</Message>
              ) : (
                <Loader />
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
      <hr />
    </Container>
  );
};

export default StatsScreen;
