import React from "react";
import { Box, TextField } from "@mui/material";

const SearchBox = ({ setKeyword }) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Search.."
        variant="outlined"
        onChange={(e) => setKeyword(e.target.value)}
      />
    </Box>
  );
};

export default SearchBox;
