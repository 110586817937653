import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DrawerHeader } from "../components/DrawerHeaderSpace";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Container,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Pagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductListScreen = () => {
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterProducts, setFilterProducts] = useState({});
  const [keyword, setKeyword] = useState("");
  const { products, error } = useSelector((state) => state.products);
  const [loading, setLoading] = useState(false);
  const [productsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    // console.log("value : " + value);
    setCurrentPage(value);
  };

  useEffect(() => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    if (
      loginStatus &&
      (jwt_decode(loginStatus).payload.role === "admin" ||
        jwt_decode(loginStatus).payload.role === "user")
    ) {
      //   dispatch(fetchProducts());
      if (keyword === "") {
        setFilterProducts(products);
      } else {
        setFilterProducts(
          products.filter((product) =>
            product.name.includes(keyword.toUpperCase())
          )
        );
        // setFilterProducts();
      }
      setLoading(true);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, products, keyword]);

  let currentProducts;
  if (loading) {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    currentProducts = filterProducts.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );
  }

  const deleteHandler = async (id) => {
    if (window.confirm("are you sure")) {
      const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
      let localToken;
      if (loginStatus) {
        localToken = loginStatus;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      };
      const response = await axios.delete(`/api/products/${id}`, config);
      // console.log(response.data);
      // navigate("/", { replace: true });
      navigate(0);
      return response?.data;
    }
  };

  const createProductHandler = async () => {
    const loginStatus = JSON.parse(localStorage.getItem("userInfo"));
    let localToken, localTenant;
    if (loginStatus) {
      localToken = loginStatus;
      localTenant = jwt_decode(loginStatus).payload.tenant;
    }
    // console.log("localToken : " + localToken);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localToken}`,
      },
    };
    const response = await axios.post(
      `/api/products/${localTenant}`,
      {},
      config
    );
    // console.log(response.data);
    navigate(0);
    return response?.data;
  };

  return (
    <>
      <Container>
        <DrawerHeader />
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: 20,
          }}
        >
          <h4>Products</h4>
          <Button variant="contained" onClick={createProductHandler}>
            <AddIcon />
            Add Products
          </Button>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            // justifyContent: "space-between",
            margin: 10,
          }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="filterProduct"
              label="Filter Products"
              variant="outlined"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              // style={{
              //   margin: 10,
              // }}
            />
          </Grid>
        </div>
      </Container>

      {loading ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 0 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>NAME</StyledTableCell>
                  <StyledTableCell>CATEGORY</StyledTableCell>
                  <StyledTableCell>PRICE</StyledTableCell>
                  <StyledTableCell>STOCK</StyledTableCell>
                  {/* <StyledTableCell>USER</StyledTableCell> */}
                  <StyledTableCell>ACTIONS</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProducts.map((product, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {product.name}
                    </StyledTableCell>
                    <StyledTableCell>{product.category}</StyledTableCell>
                    <StyledTableCell>{product.price}</StyledTableCell>
                    <StyledTableCell>{product.countInStock}</StyledTableCell>
                    {/* <StyledTableCell>{product.user.name}</StyledTableCell> */}

                    <StyledTableCell>
                      <Link to={`/admin/product/${product._id}/edit`}>
                        <EditIcon color="primary" />
                      </Link>
                      {jwt_decode(JSON.parse(localStorage.getItem("userInfo")))
                        .payload.role === "admin" ? (
                        <Button
                          variant="error"
                          onClick={() => deleteHandler(product._id)}
                        >
                          <DeleteIcon color="error" />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ margin: 8 }}>
            {filterProducts.length >= 10 ? (
              <Pagination
                count={Math.ceil(filterProducts.length / productsPerPage)}
                page={currentPage}
                onChange={handleChange}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      ) : error ? (
        <Message variant="error">{error}</Message>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProductListScreen;
