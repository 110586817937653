import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "/api/users/login";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

export const loginScreen = createAsyncThunk(
  "login/loginScreen",
  async (email, password) => {
    const response = await axios.get(BASE_URL);
    // console.log(response.data);
    return response?.data;
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    // ==> normal reducer functions go here
  },
  extraReducers(builder) {
    builder
      .addCase(loginScreen.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(loginScreen.fulfilled, (state, action) => {
        state.loading = true;
        state.products = state.products.concat(action.payload);
      })
      .addCase(loginScreen.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

export default loginSlice.reducer;
